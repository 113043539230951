/* ACTIVE USER PANEL ITEMS */

.activeuserpanelitem {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 500px;
}
.activeuserpanelitem:hover {
  background-color: #005990;
}

.userpanelselection {
  top: 64px;
  position: fixed;
  flex-direction: column;
  display: flex;
  z-index: 14;
}
.activeuserpanelitemtext {
  position: relative;
  margin-left: 18px;

  display: flex;
  z-index: 12;
  right: 0px;
  width: 484px;
  height: 64px;
  color: #ffffff;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 64px;
}

/* ICONS */

.usericon {
  position: absolute;
  width: 24px;
  top: 22px;
  left: 34px;
}

.optionicons {
  position: relative;
  margin-left: 37px;
  width: 20px;
  z-index: 13;
  cursor: pointer;
}

.userpanel_xico {
  position: absolute;
  top: 21px;
  right: 28px;
  cursor: pointer;
}

/* CAMERA SELECTOR */

.cameraselectoritem {
  width: 500px;
  height: 118px;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #005990;
}

.cameraselectoritem:hover {
  background-color: #005990;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.cameraselectoritemheader {
  position: relative;
  display: flex;
  margin-left: 32px;
  top: 16px;
  z-index: 12;
  right: 0px;
  width: 484px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.cameraselectordropdown {
  position: fixed;

  width: 441px;
  margin-top: 40px;
  margin-left: 30px;
  z-index: 50;
  /* background-color: #ffffff77; */
  /* left: calc(20% + 150px); */
  /* top: 10px; */
  height: 46px;
  border-radius: 3px;
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff2c;
}

.cameraselectordropdown:hover {
  background-color: #005285;
}

@media only screen and (max-width: 1000px) {
  .cameraselectordropdown {
    /* left: 350px; */
  }
}

.webcamselectdropdown {
  display: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.198);
  position: relative;
  top: 9px;
  width: 437px;
  height: 30px;
  z-index: 55;
  background-color: #ffffff;
  color: #000000;
}

.webcamselectdropdown a {
  width: 437px;
  height: 30px;

  padding-left: 7px;
  padding-top: 2px;

  /* background-color: rgb(255, 255, 255); */
}

.webcamselectdropdown a:hover {
  color: rgb(255, 255, 255);
  background-color: #26a551;
  /* border-radius: 3px; */
}

.cameraselectordropdown:hover .webcamselectdropdown {
  display: flex;
}

.cameraselectordropdowntxt {
  text-align: left;
  /* margin-right: 25px; */
  margin-left: 15px;
  margin-top: 7px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

/* CURRENT SCENARIO DESCRIPTION IN WEBCAM TRAINING USER PANEL */

.currentscenarioitem {
  position: relative;
  z-index: 20;
  right: 0px;
  background-color: #ffffff;
}

.currentscenarioheader {
  position: relative;
  margin-left: 32px;
  margin-top: 32px;
  z-index: 20;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.currentscenarioskills {
  position: relative;
  margin-left: 32px;
  margin-top: 6px;
  z-index: 20;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d6d6d;
}

.currentscenarioinstructions {
  position: relative;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 18px;
  z-index: 20;
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #555555;
}

.currentscenariovideo {
  position: relative;
  margin-top: 16px;
  margin-left: 32px;
  margin-right: 16px;
  margin-bottom: 30px;
  width: 85%;
  border-radius: 4px;
}

/* NEW USER PANEL */

.usrpnlheader {
  position: fixed;
  z-index: 52;
  width: 500px;
  min-width: 50px;
  height: 64px;
  transition: background-color 0.25s, color 0.25s;
}

@media only screen and (max-width: 1350px) {
  .usrpnlheader {
    right: 0px;
  }
}

@media only screen and (min-width: 1350px) {
  .usrpnlheader {
    right: calc((100% - 1350px) / 2);
  }
}

.usrpnlheader:hover {
  filter: brightness(95%);
}

.usrpnlheaderinactive {
  background-color: #ffffff;
  color: #6d6d6d;
}

.usrpnlheaderactive {
  background-color: #00619d;
  color: #ffffff;
}

.usrpnlfiller {
  position: fixed;
  z-index: 50;
  height: 100%;
  right: 0px;
  top: 64px;
  transition: background-color 0.25s, color 0.25s;
}

.usrpnltopfiller {
  position: fixed;
  z-index: 50;
  height: 64px;
  right: 0px;
  top: 0px;
  transition: background-color 0.25s, color 0.25s;
}

.usrpnltopfillerinactive {
  background-color: #ffffff00;
  z-index: 50;
}

.usrpnlfilleractive {
  background-color: #004875;
  z-index: 53;
}

.usrpnlfillerinactive {
  background-color: #f0f0f0;
  /* z-index: 49; */
}

@media only screen and (max-width: 1350px) {
  .usrpnlfiller {
    display: none;
  }
  .usrpnltopfiller {
    width: calc((100% - 1350px) / 2);
  }
}

@media only screen and (min-width: 1350px) {
  .usrpnlfiller {
    width: calc((100% - 1350px) / 2);
  }

  .usrpnltopfiller {
    width: calc((100% - 1350px) / 2);
  }
}

.usrpnldrawer {
  position: fixed;
  display: flex;
  z-index: 49;
  right: -500px;
  width: 500px;
  top: 64px;
  height: 100%;
  color: #ffffff;
  background-color: #00619d;
  transition: transform 0.25s;
  transition-delay: 0.3s;
}

@media only screen and (max-width: 1350px) {
  .usrpnldrawer {
    right: -500px;
  }
}

@media only screen and (min-width: 1350px) {
  .usrpnldrawer {
    right: calc(((100% - 1350px) / 2) - 500px);
  }
}

.usrpnldraweractive {
  transform: translate(-500px, 0px);
  z-index: 52;
}

.usrpnldrawerinactive {
  transform: translate(0px, 0px);
  /* z-index: 49; */
  /* display: none; */
}

.usrpnldrawerselection {
  /* top: 64px; */
  position: fixed;
  flex-direction: column;
  display: flex;
  z-index: 14;
}

.usrpnloverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  height: 100%;
  transition: background-color 0.25s, z-index 0.25s;
}

.usrpnloverlayactive {
  background-color: #6d6d6d60;
  z-index: 52;
}

.usrpnloverlayinactive {
  background-color: #6d6d6d00;
  z-index: 0;
  display: none;
}

.usrpnltextinactive {
  position: absolute;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  margin-left: 25px;
  line-height: 64px;

  right: 90px;
  cursor: pointer;
}

.usrpnlburgerinactive {
  position: absolute;
  top: 21px;
  right: 26px;
  cursor: pointer;
}
