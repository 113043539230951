.button {
  position: absolute;
  /* bottom: 90px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  bottom: 20px;
  right: 20px;
  width: 150px;
  background-color: #00619d;
  cursor: pointer;
  /* border-style: solid;
  border-width: 1px;
  border-color: #00619d; */
}

.button:hover {
  background-color: #004875;
}

.deadbutton {
  position: absolute;
  /* bottom: 90px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  bottom: 20px;
  right: 20px;
  width: 150px;
  background-color: #797979;
  cursor: pointer;
  /* border-style: solid;
    border-width: 1px;
    border-color: #00619d; */
}

.btntext {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  top: 7px;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
}
