.button {
  height: 46px;
  border-radius: 3px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.buttontext {
  text-align: center;
  text-transform: uppercase;
  margin-top: 8px;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
}
