.topbar {
  position: fixed;
  z-index: 51;
  box-shadow: 0px 2px 8px rgba(173, 173, 173, 0.3);
  right: 0px;
  left: 0px;
  padding: 0.5rem;
  padding-top: 15px;
  background-color: #fdfdfd;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
}

.topbarselection {
  /* background-color: antiquewhite; */
  position: absolute;
  display: grid;
  grid-template-columns: auto auto;
  /* column-gap: 30px; */

  z-index: 50;
  left: 20%;

  /* max-width: 580px; */
  /* min-width: 225px; */
  height: 64px;
  top: 0px;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .topbarselection {
    left: 200px;
  }
}

@media only screen and (min-width: 1350px) {
  .topbarselection {
    left: calc((((100% - 1350px) / 2)) + 270px);
  }
}

.topbarselectionoption {
  display: flex;
  position: relative;
  align-items: center;
  text-decoration-line: none;
}

.topbarselectionlink {
  display: block;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  font-size: 14px;
  color: #6d6d6d;
  text-decoration-line: none;
}

.greenline {
  position: absolute;

  display: block;
  background-color: #2cc25f;
  height: 4px;
  width: 100%;
  bottom: 0px;
  border-radius: 34px;
}
.nogreenline {
  display: none;
}

.topbarselectionoption:hover {
  background-color: #f5f5f5;
}

.topbarselectionoption:hover .topbarselectionlink {
  color: #444444;
}

.topbarlogo {
  position: absolute;
  z-index: 10;
  width: 20%;
  min-width: 200px;
  max-width: 270px;
  height: 64px;
  top: 0px;
  cursor: pointer;
}

.topbarlogoimage {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1350px) {
  .topbarlogo {
    left: 0px;
  }
}

@media only screen and (min-width: 1350px) {
  .topbarlogo {
    left: calc((100% - 1350px) / 2);
    /* left: 200px; */
  }
}
