.itemcontentcomponent {
  position: fixed;
  left: 20%;
  top: 65px;
  background-color: #ffffff;
  width: calc(1350px - 270px);
  /* width: 900px; */
  height: 100%;
  overflow: auto;
  margin-left: 1px;
}

@media only screen and (min-width: 1350px) {
  .itemcontentcomponent {
    left: calc(((100% - 1350px) / 2) + 270px);
  }
}

@media only screen and (max-width: 1000px) {
  .itemcontentcomponent {
    left: 200px;
  }
}

/* EDUCATION SECTION */

.edutopfade {
  position: fixed;
  /* width: calc(1350px - 70px); */
  /* height: 100px; */
  background-image: linear-gradient(white, white, rgba(255, 255, 255, 0));
  z-index: 11;
}

.eduparagraph {
  position: relative;
  left: 50px;
  top: 10px;
  width: 800px;
  text-align: justify;
  text-justify: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
  line-height: 2;
  margin-bottom: 30px;
}
.eduheader {
  position: relative;
  margin-top: 30px;
  margin-bottom: 10px;
  left: 50px;
  top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #444444;
}

.edutitlebox {
  position: relative;
  margin-top: 80px;
  margin-left: 26px;
  margin-bottom: 30px;

  width: 80%;
  height: 120px;
  border-radius: 6px;
  background-color: #00619d;
}

.edutitle {
  position: relative;
  margin-top: 90px;
  text-align: center;
  padding: 32px 0;
  /* top: 10px; */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 50px;
  color: #ffffff;

  /* margin-bottom: 30px; */
}

.edutoptitlebox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -72px);
  /* margin-left: auto;
  margin-right: auto; */
  border-radius: 35px;
  /* width: 180px; */
  height: 25px;
  border: 2px solid #00619d;
  background-color: #ffffff;
  text-align: center;
}

.edutoptitleboxtext {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #00619d;
  margin-left: 10px;
  margin-right: 10px;
}

.mediadescription {
  position: relative;
  text-align: center;
  text-justify: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d6d6d;
  margin-bottom: 15px;
  width: 60%;
  left: 150px;
}

.edufinaldiv {
  position: relative;
  width: 90%;
  height: 500px;
}

.scenarioprepimg {
  margin-left: 50px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.completedbox {
  position: relative;
  display: flex;
  margin: auto;
  /* background-color: #444444; */
  width: 150px;
  height: 50px;
  border-style: solid;
  border-radius: 5px;
  border-width: 2px;
  border-color: #444444;
}

.checkbox {
  flex: 20%;
  margin-left: 13px;
  margin-top: 13px;
}

.completedboxtext {
  flex: 60%;
  margin-top: 8px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #444444;
}
