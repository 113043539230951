/* COURSES */

.courses {
  position: fixed;
  z-index: 8;
  background-color: #ececec;
  margin: 0rem auto;
  top: 64px;
  width: 20%;
  min-width: 200px;
  height: 100%;
  max-width: 270px;
}

@media only screen and (min-width: 1350px) {
  .courses {
    margin-left: calc((100% - 1350px) / 2);

    /* left: 200px; */
  }
}

.sidebarfiller {
  position: absolute;
  z-index: 7;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
}

/* COURSE ITEMS */

.courseitem {
  display: flex;
  height: 60px;
  background-color: #ffffff;
  transition: background-color 0.2s;
}

.courseitemgreenline {
  position: absolute;
  display: flex;
  height: 60px;

  width: 4px;
  background-color: #2cc25f;
  z-index: 100;
}

.courseitemnoline {
  display: none;
}

.courseitem:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.courseitemselected {
  display: flex;
  height: 60px;

  background-color: #ececec;
}

.courseitemdescription {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  flex-flow: column-reverse;
  justify-content: flex-start;
  text-overflow: clip;
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin-left: 10%;
  flex-direction: row;
  align-items: center;
}

/* @media (min-width: 580px) {
  .courseitemdescription {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }
} */

.freetrainingitem {
  display: flex;
  height: 60px;
  text-decoration: none;
  background-color: #ffffff;
}

.freetrainingitem:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.fthorizontalline {
  position: absolute;
  left: 20px;
  right: 20px;
  height: 2px;
  background-color: #dadada;

  z-index: 9;
}
