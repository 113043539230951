.laparosticker {
  position: absolute;

  /* transform: scale(0.6, 0.6); */
  /* bottom: 10px;
  right: 0px; */
  /* bottom: 0px; */
  bottom: 20px;
  left: 20px;
  z-index: 21;
  border-radius: 21px;
  /* box-shadow: 4px 9px 21px -4px rgba(0, 0, 0, 0.28); */
  background-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 3px;
  border-color: #00619d;
}
