.background {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
}

.text {
  text-align: center;
  color: rgb(119, 119, 119);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10%;
  margin-left: 10%;
  margin-right: 10%;
}

.logohomeimage {
  position: relative;
  display: block;
  margin-top: 40%;
  margin-left: auto;
  margin-right: auto;
}
