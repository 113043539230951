.itemcontentcomponent {
  position: fixed;
  left: 20%;
  top: 65px;
  background-color: #ffffff;
  width: calc(1350px - 270px);
  /* width: 900px; */
  height: 100%;
  overflow: auto;
  margin-left: 1px;
}

@media only screen and (min-width: 1350px) {
  .itemcontentcomponent {
    left: calc(((100% - 1350px) / 2) + 270px);
  }
}

@media only screen and (max-width: 1000px) {
  .itemcontentcomponent {
    left: 200px;
  }
}

/* BUTTON */

.buttonstarttraining {
  position: fixed;
  /* float: right; */
  /* left: calc(20% + 950px); */
  z-index: 1;
  margin-left: 770px;
  bottom: 20px;
  background-color: #2cc25f;
  width: 148px;
  height: 46px;
  border-radius: 3px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-decoration: none;
}

/* @media only screen and (min-width: 1200px) {
  .buttonstarttraining {
    right: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .buttonstarttraining {
    left: 1080px;
  }
} */

.buttonstarttraining:hover {
  background-color: #26a350;
}

.buttontext {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 46px;
}

.scenariolistrightsidefiller {
  display: none;
  position: fixed;
  left: calc(20% + 902px);
  top: 65px;
  right: 0px;
  z-index: 1;
  height: 100%;
  /* background-color: rgb(255, 255, 255); */
}
