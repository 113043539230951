.webcamplayer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px;
  gap: 0px;
  z-index: 52;

  position: absolute;
  width: 112px;
  height: 44px;

  border-radius: 45px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.11);

  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 5%);
  bottom: 25px;
}

.webcambuttonrecord {
  z-index: 20;
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 45px 0px 0px 45px;
  cursor: pointer;
}

.webcambuttonrecord:hover {
  z-index: 100;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.205);
}

.webcamrecordico {
  position: relative;
  margin-left: 33%;
  margin-top: 12px;
  height: 20px;
  width: 20px;
}

.webcambuttonfullscr {
  z-index: 20;
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 0px 45px 45px 0px;
  cursor: pointer;
}

.webcambuttonfullscr:hover {
  z-index: 100;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.205);
}

.webcamfullscrico {
  position: relative;
  margin-left: 33%;
  margin-top: 12px;
  height: 20px;
  width: 20px;
}
