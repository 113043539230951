.webcamview {
  position: fixed;
  margin-right: auto;
  margin-top: 64px;
  object-fit: fill;
  z-index: 51;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0%);
  width: 100%;
}

.examplebox {
  position: fixed;
  width: 900px;
  height: 500px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #f5f5f5;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  top: 80px;
  left: 31%;
}
