.helpprompt {
  width: 500px;
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #00619d;
}

.needhelptext {
  position: absolute;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  left: 30px;
  top: 10px;
}

.contactustext {
  position: absolute;
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
  top: 27px;
  left: 33px;
}
