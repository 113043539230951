.scenariolist {
  position: fixed;
  /* right: 1px; */
  /* max-width: 1000px; */
  left: 20%;
  top: 64px;
  height: 100%;
  margin-left: 1px;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  right: 1px;
  z-index: 49;
}

@media only screen and (min-width: 1350px) {
  .scenariolist {
    margin-left: calc((100% - 1350px) / 2);
    left: 271px;
    right: calc((100% - 1350px) / 2);
  }
}

@media only screen and (max-width: 1000px) {
  .scenariolist {
    position: fixed;
    right: 1px;
    margin-right: 10px;
    left: 200px;
    top: 64px;
    height: 100%;
    margin-left: 1px;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    min-width: 700px;
  }
}

.scenariolistitem {
  position: relative;

  height: 146px;
  justify-content: start;
  margin-bottom: 1px;

  background-color: #ffffff;
  scroll-snap-align: start;

  max-width: 1080px;
  transition: background 0.2s;
}

.scenariolistitem:hover {
  background: #e6f0f6;
  cursor: pointer;
}

.scenariolistfiller {
  position: absolute;
  z-index: 7;
  background-color: rgb(255, 255, 255);
  width: 1100px;
  height: 700px;
}

.scenariolistrightsidefiller {
  position: fixed;
  display: none;
  left: calc(20% + 902px);
  top: 64px;
  right: 0px;
  z-index: 1;
  height: 100%;
}

/* SCENARIO ITEM */

.slscenariotitle {
  display: flex;
  left: 15px;
  top: 15px;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  color: #444444;
  font-style: normal;
}

.slscenarioskill {
  display: flex;
  left: 15px;
  position: relative;
  top: 15px;
  font-weight: normal;
  font-size: 12px;
  color: black;
}

/* EDUCATION ITEM */

.sleducationtitle {
  display: flex;
  left: 15px;
  top: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  color: #444444;
}

.sleducationitemskills {
  display: flex;
  width: 400px;
  word-wrap: inherit;
  left: 15px;
  position: relative;
  top: 15px;
  font-weight: normal;
  font-size: 12px;
  word-wrap: normal;
  color: black;
}

/* Spinner: */

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spinner::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 4px #cfd0d1;
  border-bottom-color: #00619d;
  border-radius: 50%;
  content: "";
  height: 40px;
  position: absolute;
  top: 10%;
  left: 20%;
  transform: translate3d(-50%, -50%, 0);
  width: 40px;
  will-change: transform;
}

.passedico {
  position: absolute;
  bottom: 14px;
  right: 16px;
}

.listthumb {
  display: flex;
  float: left;

  height: 100%;
  width: 230px;
  transition: width 0.2s;
}

.listthumb:hover {
  width: 250px;
}

.listthumbimg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;

  width: 205px;
  height: 115px;

  border-radius: 4px;
  background-color: #cccccc;
  transition: width 0.2s, height 0.2s;
}

.listthumbimg:hover {
  width: 225px;
  height: 126px;
}

.eduico {
  position: absolute;
  height: 40px;
  top: 37%;
  left: 90px;
}
