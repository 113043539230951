/* SCENARIO PREPARATION INSTRUCTIONS */

.scenariopreparationbox {
  position: relative;
  width: 100%;
  height: 400px;
  /* text-justify: auto; */
  background-color: #f5f5f5;
}

.scenariopreparationheader {
  top: 10px;
  position: relative;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #263238;
  margin: 20px 75px;
}

.scenariopreptext {
  text-align: justify;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #555555;
  margin: 20px 75px;
}

.scenarioprepimg {
  display: block;
  height: 50%;
  margin: 10px auto;
  border-radius: 5px;
}
