.itemcontenttophalf {
  position: relative;
  top: 25px;
  width: 900px;
  margin-left: 50px;

  /* margin-bottom: 310px; */
  overflow-y: auto;
  overflow-x: hidden;
}

.header {
  position: relative;
  left: 45px;
  top: 10px;
  font-style: normal;
  font-weight: 600;
  color: #263238;
  font-size: 24px;
}

.skills {
  position: relative;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #6d6d6d;
  left: 16px;
  top: 15px;
}

.description {
  text-align: justify;
  text-justify: auto;
  position: relative;
  left: 16px;
  top: 30px;
  width: 850px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #555555;
}
/* SCENARIO DESCRIPTION INSTRUCTIONS */
.instructionheader {
  position: relative;
  left: 16px;
  top: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #263238;
}

.instructiongrid {
  position: relative;
  display: grid;
  grid-template-columns: 452px auto;
}

.scenarioimg {
  margin-top: 15px;
  margin-left: 16px;
  width: 452px;
  border-radius: 4px;
}

.scenarioinstructions {
  position: relative;
  margin-left: 40px;
  margin-right: 16px;
  margin-top: 15px;
  text-align: justify;
  text-justify: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #555555;
  width: 83%;
}

.horizontalline {
  margin-top: 45px;
  margin-left: 20px;
  margin-right: 20px;
  color: #e5e5e5;
  border-top: 1px;
}

.checkbox {
  position: relative;
  left: 15px;
  top: 45px;
}
