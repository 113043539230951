.box {
  position: fixed;
  left: 40%;
  margin-left: 4px;
  top: 200px;
  z-index: 100;
  width: 400px;
  height: 60px;
  background-color: rgba(255, 177, 177, 0.72);
  border-radius: 3px;
}

.text {
  z-index: 41;
  font-size: 16pt;
  text-align: center;
  margin-top: 5px;
  color: rgb(0, 0, 0);
}

.textscenario {
  z-index: 41;
  font-size: 12pt;
  text-align: center;
  margin-top: 0px;
  color: rgb(0, 0, 0);
}
