.languageselectoritem {
  width: 500px;
  height: 108px;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #00619d;
}

.languageselectorheader {
  position: relative;
  display: flex;
  margin-left: 32px;
  top: 16px;
  z-index: 12;
  right: 0px;
  width: 484px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.languageselectordropdown {
  position: fixed;
  width: 441px;
  margin-top: 40px;
  margin-left: 30px;
  z-index: 50;
  height: 46px;
  border-radius: 3px;
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff2c;
}

.languageselectordropdowntxt {
  text-align: left;
  margin-left: 55px;
  margin-top: 7px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.languageselectdropdown {
  display: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.198);
  position: relative;
  top: 9px;
  width: 437px;
  height: 30px;
  z-index: 55;
  background-color: #ffffff;
  color: #000000;
}

.languageselectdropdown a {
  width: 437px;
  height: 30px;
  padding-left: 7px;
  padding-top: 2px;
}

.languageselectdropdown a:hover {
  color: rgb(255, 255, 255);
  background-color: #26a551;
}

.languageselectordropdown:hover .languageselectdropdown {
  display: flex;
}

.languageflag {
  position: absolute;
  line-height: 100%;
  top: 8px;
  left: 15px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
