.webcamtimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  z-index: 51;
  position: absolute;
  width: 170px;
  height: 29px;

  background: #ffffff;
  border-radius: 40px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.11);

  left: calc(50% - 150px);
  margin-right: -50%;
  transform: translate(-50%, 5%);
  bottom: 30px;
}

.webcamhandledownload {
  position: fixed;
  width: 75px;
  height: 25px;
  background-color: #00426b00;
  cursor: pointer;
}

.downloadbut {
  width: 160px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #00619d;
}

.webcamtimerrecordico {
  animation: blink-animation 1s steps(2, start) infinite;
  -webkit-animation: blink-animation 1s steps(2, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.webcamtimertimer {
  float: right;
  width: 150px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
