.loadingscreenbackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.loadingscreentext {
  color: #6e6e6e;
}

.loadingmsg {
  position: fixed;
  font-weight: 500;
  font-size: small;
  top: 10px;
  right: 10px;
  color: #6e6e6e;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  /* animation: 1.5s linear infinite spinner; */
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: spinner;

  animation-play-state: running;
  border: solid 4px #cfd0d1;
  border-bottom-color: #00619d;
  border-radius: 50%;
  content: "";
  height: 40px;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 40px;
  will-change: transform;
}
