/* return next prev nav */

.nextprevbackroundgrad {
  position: fixed;

  height: 60px;
  width: calc(1350px - 270px);

  background-image: linear-gradient(
    rgb(255, 255, 255),
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0)
  );
  z-index: 12;
}

.returnbutton {
  position: fixed;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 10px;
  border-radius: 3px;
  height: 30px;
  width: 100px;
  z-index: 12;
}

.returnbutton:hover {
  background-color: #94949417;
}

.returnbuttontext {
  position: relative;
  user-select: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  top: 4px;
  right: 2px;
  text-align: right;
  margin-right: 5px;
  color: #00619d;
}

.returnbuttonarrow {
  position: fixed;
  margin-top: 3px;
  margin-left: 3px;
}

.nextbutton {
  position: fixed;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 850px;
  border-radius: 3px;
  height: 30px;
  width: 120px;
  z-index: 12;
}

.nextbutton:hover {
  background-color: #94949417;
}

.nextbuttontext {
  position: relative;
  user-select: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  top: 4px;
  right: -30px;
  text-align: left;
  color: #00619d;
}

.prevbutton {
  position: fixed;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 725px;
  border-radius: 3px;
  height: 30px;
  width: 120px;
  z-index: 12;
}

.prevbutton:hover {
  background-color: #94949417;
}

.prevbuttontext {
  position: relative;
  user-select: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  top: 4px;
  right: 20px;
  text-align: right;
  margin-right: 5px;
  color: #00619d;
}

.leftarrico {
  position: fixed;

  margin-left: 95px;
}

.rightarrico {
  position: fixed;
}
