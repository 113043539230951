.accesscodebackground {
  z-index: 99;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #00619d46;
}

.accesscodewindow {
  z-index: 100;
  position: fixed;
  width: 500px;
  height: 470px;
  border-radius: 6px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);
}

.accesscodetext {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #555555;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.accesscodetitle {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #555555;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.erroraccesscodetext {
  position: relative;
  /* bottom: 60px; */
  font-weight: 600;
  font-size: 14px;
  width: 200px;
  float: right;
  right: 10px;
  top: 40px;
  text-align: center;
  color: rgb(216, 32, 0);
}
