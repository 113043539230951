.cameraselector {
  position: fixed;
  z-index: 51;
  width: 225px;
  height: 64px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}

@media only screen and (max-width: 1050px) {
  .cameraselector {
    left: 315px;
    /* right: 200px; */
  }
}

@media only screen and (max-width: 1350px) {
  .cameraselector {
    right: 500px;
    /* right: 200px; */
  }
}

@media only screen and (min-width: 1350px) {
  .cameraselector {
    right: calc(((100% - 1350px) / 2) + 500px);
    /* left: 200px; */
  }
}

.cameraselector:hover {
  background-color: #e4e4e482;
  z-index: 55;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); */
}

.cameraselectoritemheader {
  position: relative;
  display: flex;
  margin-left: 12px;
  top: 2px;
  z-index: 53;
  right: 0px;
  width: 484px;
  color: #a6a6a6;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.cameraselectordropdown {
  position: fixed;
  width: 206px;
  margin-top: 20px;
  margin-left: 10px;
  z-index: 53;
  height: 36px;
  border-radius: 3px;
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  border-color: #d7d7d7;
}

.cameraselectordropdown:hover {
  background-color: #dedede;
}

.webcamselectdropdown {
  display: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.198);
  position: relative;
  top: 0px;
  width: 300px;
  height: 30px;
  z-index: 55;
  background-color: #ffffff;
  color: #000000;
}

.webcamselectdropdown a {
  width: 437px;
  height: 30px;
  padding-left: 7px;
  padding-top: 2px;
}

.webcamselectdropdown a:hover {
  color: rgb(255, 255, 255);
  background-color: #26a551;
}

.cameraselectordropdown:hover .webcamselectdropdown {
  display: flex;
}

.cameraselectordropdowntxt {
  text-align: left;
  margin-left: 15px;
  margin-top: 3px;
  color: #00619d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}
